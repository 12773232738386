* {
  background: url(https://media.giphy.com/media/aRZ4vTsHnyW6A/giphy.gif);
  background-size: cover;
  background-repeat: no-repeat;
}

.main {
  color: white;
  align-content: center;
  text-align: center;
}

h1 {
  font-size: 5em;
}

p {
  font-size: 1.5em;
}

a {
  color: white;
}